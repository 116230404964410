<template>
<div class="card mb-3 shadow">
    <div class="card-header">
        <h2 class="felcim">Péter Rebrus - Péter Szigetvári - Miklós Törkenczy</h2>
        <h1 class="focim">Dark Secrets of Hungarian vowel harmony</h1>
    </div>
    <div class="card-body">
        <div class="content-container">
            <div class="text-content">
                <p>Cillum cillum deserunt dolor ea cupidatat qui labore. Nulla consequat nisi eiusmod duis adipisicing cillum esse consectetur eu aute. Non nisi cupidatat minim proident. Exercitation do nostrud commodo fugiat exercitation. Cillum ea id incididunt minim amet. Labore et id velit reprehenderit exercitation proident laborum aliqua culpa cupidatat velit consequat mollit commodo. Magna eiusmod ea veniam in cupidatat fugiat tempor sit nostrud deserunt voluptate minim non aliqua.</p>
                <p>Tempor qui id nulla deserunt et enim laboris. Eiusmod eiusmod irure nisi velit duis et enim ullamco commodo aute tempor amet velit cillum. Cupidatat nisi elit veniam labore sunt. Enim tempor occaecat et velit esse ea aute do occaecat nostrud ex. Dolore ad cillum do mollit ex sunt ad proident ut adipisicing cillum sunt. Commodo dolore proident nostrud excepteur deserunt duis amet cupidatat elit quis.</p>
            </div>
            <div class="vertical-line"></div>
        </div>
    </div>
    <div class="card-footer">
        <small>Rebrus Péter – Szigetvári Péter – Törkenczy Miklós (2012). Dark secrets of Hungarian vowel harmony. In Eugeniusz Cyran -- Bogdan Szymanek -- Henryk Kardela (eds.), Sound, structure and sense: Studies in Memory of Edmund Gussmann, 491-508. Lublin: Lublin Wydawnictwo KUL.</small>
        <!-- A letöltési link hozzáadása -->
        <h2 class="text-end">
            <span class="visually-hidden">Cikk letöltése</span>
            <a href="pdf-fajlok/rebrus-etal-2012-2.pdf" download aria-label="<?= $lg['cikkek']['download_article'] ?>">
                <i class="bi bi-download"></i>
            </a>
        </h2>
    </div>
</div>
</template>

<script>
export default {
    name: 'RouteCikkek'
}
</script>

<template>
<div class="card mb-3 shadow">

    <div class="card-header">&nbsp;</div>

    <div class="card-body">
        <div class="content-container">
            <h2 class="focim">Üdv!</h2>
        </div>
    </div>

    <div class="card-footer">&nbsp;</div>

</div>
</template>

<script>
export default {
    name: 'RouteFonotabor2024'
}
</script>

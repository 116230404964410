<template>
  <footer class="bg-light py-3 mt-4">
    <div class="container">
      <p class="text-center">© 2024 Fonotábor. Minden jog fenntartva.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

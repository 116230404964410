<template>
  <div id="app">
    <AppNavbar/>
    <MainContent/>
    <AppFooter/>
  </div>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue'
import MainContent from './components/MainContent.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppNavbar,
    MainContent,
    AppFooter
  }
}
</script>

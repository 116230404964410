<template>
<!-- Navigáció -->
<header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-custom shadow">
        <div class="container">
            <router-link to="/" class="navbar-brand" style="color: #fff !important;">fonoːtaːbor</router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="<?= $lg['navigation']['toggle_navigation'] ?>">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                    <!-- Cikkek link -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/cikkek">Cikkek</router-link>
                    </li>

                    <!-- Fonotábor link -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/fonotabor2024">Fonotábor 2024</router-link>
                    </li>

                    <!-- Helyszín link -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/helyszin">Helyszín</router-link>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
</header>
<!-- /. Navigáció -->
</template>

<script>
export default {
    name: 'AppNavbar'
}
</script>

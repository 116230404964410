<template>
  <main class="container mt-4">
    <div class="row">

      <!-- Fő tartalom -->
      <div class="col-md-9">

        <!-- Téma -->
        <div class="article-header">
          <i class="bi bi-diamond-fill"></i>
          <h1 class="tartalom">fonoːtaːbor</h1>
        </div>
        <!-- /. Téma -->

        <!-- Tartalom -->

        <router-view></router-view>

        <!-- /. Tartalom -->

      </div>
      <!-- /. Fő tartalom -->

      <!-- Jobb oldali tartalom -->
      <aside class="col-md-3 mb-3 text-center">
        <h2 class="jobblogo">fonoːtaːbor</h2>
      </aside>
      <!-- /. Jobb oldali tartalom -->

    </div>
  </main>
</template>

<script>
export default {
  name: 'MainContent'
}
</script>

import { createRouter, createWebHistory } from 'vue-router';
import Cikkek from '../components/route-cikkek.vue';
import Fonotabor2024 from '../components/route-fonotabor2024.vue';
import Helyszin from '../components/route-helyszin.vue';
import Home from '../components/route-home.vue';

const routes = [
  {
    path: '/cikkek',
    name: 'RouteCikkek',
    component: Cikkek
  },
  {
    path: '/fonotabor2024',
    name: 'RouteFonotabor2024',
    component: Fonotabor2024
  },
  {
    path: '/helyszin',
    name: 'RouteHelyszin',
    component: Helyszin
  },
  {
    path: '/',
    name: 'RouteHome',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
